import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import './Login.css';
import { refreshPage } from './utils';
import { login } from './api';

const LoginPage = () => {
  const [pass, setPass] = useState('test');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { success, error } = await login(pass);
    if(success) {
      refreshPage();
    } else {
      console.log(error);
    }
  }

  return (
    <div className="App">
      <header className="Login-header">
        <div className="Login-box">
          <TextField
            margin="normal" required fullWidth id="pass" label="Nibs Go Here" name="pass" autoComplete="current-password" autoFocus type="password"
            onChange={e => setPass(e.target.value)}
          />
          <Button variant="contained" onClick={handleSubmit}>Send</Button>
        </div>
      </header>
    </div>
  );
};

export default LoginPage;
