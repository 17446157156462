import { Position, Trade, TradeData, TradeMap } from "./types";

// configure as needed
const TOTAL_POSITIONS = 2;

export const refreshPage = () => window.location.reload();

export const calcChange = (a: number, b: number): number => {
  return (b / a) - 1;
}

export const toFixedNumber = (input: number | string, places: number) => {
  const num = parseFloat(input.toString());

  let strFactor = '1';
  for(let i=0; i<places; i++) {
    strFactor += '0';
  }

  const factor = parseInt(strFactor);
  const roundedDown = Math.floor(num * factor) / factor;
  return parseFloat(roundedDown.toFixed(places).toString());
}

export const crunchTrades = (trades: Trade[]): { tradeData: TradeData[], positions: Position[] } => {
  const tradeData:TradeData[] = [];
  const positions:Position[] = [];

  trades.sort((a, b) => b.time - a.time);
  trades = trades.slice(0, 800); // keep only the last 8 hundo

  const tradeMap: TradeMap = {};
  for(let i=0; i<trades.length; i++) {
    const curr = trades[i];
    let existing = tradeMap[curr.currency];
    if(existing === undefined) {
      existing = [];
      tradeMap[curr.currency] = existing;
    }
    existing.push(curr);
  }

  Object.values(tradeMap).forEach(e => {
    for(let i=0; i<e.length; i++) {
      const curr = e[i], nxt = e[i+1];

      if(!nxt || (i===0 && curr.tradeType === 'BUY')) {
        if(curr.tradeType === 'BUY') {
          // this is an pending buy
          positions.push({
            currency: curr.currency,
            open: true,
            value: curr.actualPrice * curr.units
          });

          tradeData.push({
            ...curr
          });

          continue;
        }
        
        if(!nxt) {
          break;
        }
      }
      
      // this is a matched pair, so advance again
      i++;

      // add the buy
      tradeData.push({
        ...nxt
      });

      // add the sell
      tradeData.push({
        ...curr,
        settled: calcChange(nxt.actualPrice, curr.actualPrice)
      });
    }
  });

  tradeData.sort((a, b) => b.time - a.time);

  for(let i=0; i<trades.length; i++) {
    if(positions.length === TOTAL_POSITIONS) {
      break;
    }

    const curr = trades[i];
    if(curr.tradeType === 'SELL') {
      positions.push({
        currency: curr.currency,
        open: false,
        value: curr.actualPrice * curr.units
      })
    }
  }

  return {
    tradeData, positions
  };
}

interface ChartDataPoint {
  time: number;
  value: number;
}

interface TmpDataPoint {
  tradeType: string;
  currency: string;
  value: number;
  time: number;
}

export const getChartData = (data: TradeData[]) => {
  data = data.filter(e => e.units > 0);
  data.sort((a, b) => a.time - b.time);

  let a:TmpDataPoint = {} as TmpDataPoint;
  let b:TmpDataPoint = {} as TmpDataPoint;

  const dataPoints:ChartDataPoint[] = [];

  for(let i=0; i<data.length; i++) {
    const curr: TmpDataPoint = {
      tradeType: data[i].tradeType,
      currency: data[i].currency,
      value: data[i].actualPrice * data[i].units,
      time: data[i].time
    };
    
    if(a.currency === curr.currency && curr.tradeType === 'SELL') {
      // a is now sold
      a = {
        ...curr,
        currency: 'USD'
      };
    } else if(b.currency === curr.currency && curr.tradeType === 'SELL') {
      // b is now sold
      b = {
        ...curr,
        currency: 'USD'
      };
    } else if(curr.tradeType === 'BUY' && (!a.currency || a.currency === 'USD')) {
      // buy a
      a = curr;
    } else if(curr.tradeType === 'BUY' && (!b.currency || b.currency === 'USD')) {
      // buy b
      b = curr;
    } else {
      if(!a.currency || !b.currency) {
        continue;
      }
      console.log({a, b, curr})
      console.error('this should never happen');
    }

    if(a.currency && b.currency) {
      dataPoints.push({
        time: Math.max(a.time, b.time),
        value: a.value + b.value
      });
    }
  }

  return dataPoints;
}
