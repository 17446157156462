import React from 'react';
import './TradeTable.css';
import { TradeData } from './types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { calcChange, toFixedNumber } from './utils';
import moment from 'moment';

interface DataProps {
  trades: TradeData[]
}

const TradeTable = (props: DataProps) => {
  const { trades } = props;

  return (
    <TableContainer component={Paper} className="dashboard-table">
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell align="right">Settled</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Target</TableCell>
            <TableCell align="right">Actual</TableCell>
            <TableCell align="right">Diff</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trades.map((trade) => (
            <TableRow
              key={`${trade.time}#${trade.currency}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className={trade.settled === undefined ? 'neither' : (trade.settled > 0 ? 'gain' : 'loss')}
            >
              <TableCell component="th" scope="row">
                {moment.unix(trade.time).format('YYMMDD HH:mm:ss')}
              </TableCell>
              <TableCell align="right">{toFixedNumber(trade.settled || 0, 4)}</TableCell>
              <TableCell align="right">{trade.currency}</TableCell>
              <TableCell align="right">{trade.tradeType}</TableCell>
              <TableCell align="right">{toFixedNumber(trade.targetPrice, 8)}</TableCell>
              <TableCell align="right">{toFixedNumber(trade.actualPrice, 8)}</TableCell>
              <TableCell align="right">{toFixedNumber(calcChange(trade.targetPrice, trade.actualPrice), 5)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TradeTable;
