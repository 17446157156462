export const logout = async () => {
  await fetch('/api/auth/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const login = async (pass: string) => {
  const data = await fetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ pass })
  });

  return data.json();
}

export const getAuthStatus = async () => {
  const response = await fetch('/api/auth/status');
  return response.json();
}
