import React, { useEffect, useState } from 'react';
import LoginPage from './LoginPage';
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard';
import { getAuthStatus } from './api';

type AppState = 'LOGGEDOUT' | 'LOGGEDIN' | 'LOGINPAGE';

const App = () => {
  const [appState, setAppState] = useState<AppState>('LOGGEDOUT');

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const { isLoggedIn } = await getAuthStatus()
        if(isLoggedIn) {
          setAppState('LOGGEDIN');
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchAuthStatus();
  }, []);

  switch (appState) {
    case 'LOGGEDIN':
      return <Dashboard />
    case 'LOGINPAGE':
      return <LoginPage />
    default:
      return (
        <div className="App">
          <header className="App-header" onClick={() => setAppState('LOGINPAGE')}>
            <img src={logo} className="App-logo" alt="logo" />
          </header>
        </div>
      );
  }
};

export default App;
