import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import './Dashboard.css';
import { crunchTrades, getChartData, refreshPage } from './utils';
import TradeTable from './TradeTable';
import { Position, TradeData } from './types';
import { logout } from './api';
import { Typography } from '@mui/material';
import { LineChart, Line, ResponsiveContainer, YAxis, CartesianGrid } from 'recharts';

const doLogout = async () => {
  await logout();
  refreshPage();
}

type LoadState = "LOADED" | "LOADING" | "NOTLOADED";

const Positions = (props: { positions: Position[] }) => {
  const { positions } = props;
  
  if(positions.length < 2) {
    return <div />
  }

  const [ pA, pB ] = positions;

  return (
    <div>
      <Typography mt={2} className={pA.open ? "position-active" : "position-inactive"} display="inline">{pA.currency}:{pA.value.toFixed(2)}</Typography>
      <Typography mt={2} className="position-active" display="inline"> :: </Typography>
      <Typography mt={2} className={pB.open ? "position-active" : "position-inactive"} display="inline">{pB.currency}:{pB.value.toFixed(2)}</Typography>
      <Typography mt={2} className="position-active" display="inline"> = </Typography>
      <Typography mt={2} className="position-active" display="inline">{(pA.value+pB.value).toFixed(2)}</Typography>
    </div>
  )
}

const Chart = (props: { tradeData: TradeData[] }) => {
  const data = getChartData([...props.tradeData])
    .map(e => ({ time: e.time, value: Math.floor(e.value) }));

  const domain = data.map(e => e.value);

  return (
    <ResponsiveContainer width={'99%'} height={100}>
      <LineChart
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
        data={data}
      >
        <YAxis domain={[Math.min(...domain), Math.max(...domain)]} />
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" vertical={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}

const Dashboard = () => {
  const [trades, setTrades] = useState<TradeData[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [loadState, setLoadState] = useState<LoadState>("NOTLOADED");

  const getTrades = async () => {
    if(loadState !== "NOTLOADED") {
      return;
    }

    setLoadState("LOADING");

    try {
      const response = await fetch('/api/data/trades?' + new URLSearchParams({ limit: '100' }));
      const respJSON = await response.json();
      
      const { positions, tradeData } = crunchTrades(respJSON.trades);
      setTrades(tradeData);
      setPositions(positions);
      setLoadState("LOADED");
    } catch (error) {
      console.log('error', error);
      refreshPage();
    }
  };

  useEffect(() => {
    getTrades();
  }, []);

  return (
    <div className="Dashboard">
      <header className="Dashboard-header">
        <Positions positions={positions} />
        <Chart tradeData={trades} />
        <ButtonGroup aria-label="outlined button group" className="dashboard-buttons">
          <Button variant="contained" color="inherit" className="logout-button" onClick={() => doLogout()}>Logout</Button>
          <Button variant="contained" color="primary" className="refresh-button" onClick={() => setLoadState("NOTLOADED")}>{loadState === "LOADED" ? 'Refresh' : 'Refreshing...'}</Button>
        </ButtonGroup>
        
        <TradeTable trades={trades} />
      </header>
    </div>
  );
};

export default Dashboard;
